@import "./variables.scss";

button {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  // .mat-button-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-weight: 700 !important;
    letter-spacing: normal !important;
    mat-icon {
      margin-inline-end: 6px !important;
      height: 20px !important;
      width: 20px !important;
      font-size: 20px !important;
    }
  // }
}

mat-icon {
  margin-inline-end: 6px;
  height: 20px !important;
  width: 20px !important;
  font-size: 20px !important;
}

.mat-stroked-button {
  border: 1px solid #041146 !important;
}

.mat-button {
  min-width: 43px !important;
}

.btn-light-weight .mat-button-wrapper {
  font-weight: 400;
}

.mat-drawer-container {
  width: 100%;
  min-height: 100vh;
}

.mat-drawer-inner-container {
  width: 255px !important;
  background: $color-blue-1;
  @media screen and (max-width: 767px) {
    width: 100vw !important;
  }
}

.mat-dialog-actions {
  padding: unset !important;
  margin-bottom: unset !important;
}

.mat-dialog-container {
  padding: 0 !important;
  background: transparent !important;
}

.row {
  margin: 0;
  padding: 0;
}

.mat-card {
  box-shadow: 2px 4px 20px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  height:50px;
  max-width: 300px !important;
  min-height: 150px !important;
  border-radius: 10px !important;

  @media (max-width: 767px) {
    max-width: 100% !important;
  }
}

.mat-card-settings {
  box-shadow: 1px 1px 5px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  width: 95% !important;
  height: 50px !important;
  border-radius: 5px !important;
  justify-content: 'center' !important;
  margin: 15px;

  @media (max-width: 767px) {
    max-width: 100% !important;
  }
}

.ngx-pagination .current {
  background: $color-blue-1 !important;
  color: $color-white-1;
  cursor: default;
  border-radius: 50% !important;
  padding: 2px 8px !important;
}

input:focus-visible {
  outline: none !important;
}

.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent
    url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
    no-repeat 98% center;
  background-size: 12px;
  background-position-x: calc(100% - 12px);
}

// .ng-star-inserted {
//   width: 100% !important;
// }

// .form-group{
//     margin-bottom:0.5rem !important;
// }

.form-control:disabled,
.form-control[readonly] {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.form-control.enabled-grey {
  background: $color-grey-1;
  padding: 15px 10px;
  border: 1px solid #e5e7ec;
}

.form-control {
  border-radius: 7px !important;
}

hr {
  border-top: 2px solid $color-blue-4;
}

.mat-body,
.mat-body-1,
.mat-typography,
.mat-h1,
.mat-headline,
.mat-typography h1,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab,
.mat-h2,
.mat-title,
.mat-typography h2,
.mat-list-base .mat-subheader,
.mat-list-item,
.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
  font-family: "Assistant", sans-serif;
}

.mat-select-arrow {
  background-image: url("../assets/arrow-down.png");
  background-repeat: no-repeat;
  background-size: 12px;
  border: 0 !important;
  width: 12px !important;
  height: 10px !important;
  margin: 4px 4px !important;
}

.mat-typography h1 {
  font-weight: 700;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: url("../assets/arrow-right.png") !important;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: url("../assets/arrow-left.png") !important;
}

.cdk-overlay-pane {
  @media (max-width: 767px) {
    max-width: 100vw !important;
    max-height: 100vw !important;
    position: absolute;

    .global-dialog-wrapper {
      height: 100vh !important;
      width: 100vw !important;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: unset !important;
    }
  }
}

.ngx-pagination {
  padding: 0;
}
.mat-list-item-overried:last-child {
  padding-bottom: unset !important;
}

.dialog-btns {
  button {
    min-width: fit-content !important;
  }
}

.delete-icon {
  color: red;
}

.mat-select-panel {
  height: 9em;
  margin-top: 10px !important;
}

.clearAns,
.clearAns:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.product-card {
  padding-top: 3rem !important;
}

.settings {
  justify-content: 'center';
}

// .clearAns:hover {
//   text-decoration: underline !important;
// }
