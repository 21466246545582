/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap");
@import "./scss/variables.scss";
@import "./scss/overrides.scss";
@import "./scss/globals.scss";

html,
body {
    height: 100%;
    font-family: "Assistant", sans-serif;
}
body {
    margin: 0;
    // font-family: Roboto, "Helvetica Neue", sans-serif;
}

a:hover {
    text-decoration: none !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.mdc-text-field--filled:not(.mdc-text-field--disabled){
    background-color: transparent !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover{
    background-color: transparent !important;
}
