$color-blue-1: rgba(17, 43, 68, 1);
.color-blue-1 {
    color: $color-blue-1;
}

$color-blue-2: rgba(229, 231, 236, 1);
.color-blue-2 {
    color: $color-blue-2;
}

$color-blue-3: rgba(153, 211, 218, 0.2);
.color-blue-3 {
    color: $color-blue-3;
}

$color-blue-4: rgba(14, 169, 203, 1);
.color-blue-4 {
    color: $color-blue-4;
}

$color-blue-5: rgba(13, 80, 105, 1);
.color-blue-5 {
    color: $color-blue-5;
}

$color-grey-1: #f1f1f3;
.color-grey-1 {
    color: $color-grey-1;
}

$color-grey-2: #e5e7ec;
.color-grey-2 {
    color: $color-grey-2;
}

$color-grey-3: #65666f;
.color-grey-3 {
    color: $color-grey-3;
}

$color-black-1: #252733;
.color-black-1{
    color: $color-black-1;
}

$color-white-1: white;
.color-white-1 {
    color: $color-white-1;
}

$border-solid: $color-blue-5 1px solid ;
.border-solid {
    border:$border-solid;
}
$font-tiny: 14px;
.text-tiny {
    font-size: $font-tiny;
}

$font-small:16px;
.text-small {
    font-size: $font-small;
}

$font-normal:16px !important;
.text-normal {
    font-size: $font-normal;
}

$font-normal-med:20px;
.text-normal-med {
    font-size: $font-normal-med;
}

$font-normal-med-increase:calc(1vw + 4px);
.text-normal-med-increase {
    font-size: $font-normal-med-increase;
}

$font-normal-small-increase:calc(1vw + 3px);
.text-normal-small-increase {
    font-size: $font-normal-small-increase;
}

$font-normal-increase:calc(1vw + 5px);
.text-normal-increase {
    font-size: $font-normal-increase;
}

$font-medium: 22px;
.text-medium {
    font-size: $font-medium;
}

$font-large: 24px;
.text-large {
    font-size: $font-large;
}

$font-semi-huge: 28px;
.text-semi-huge {
    font-size: $font-semi-huge;
}

$font-huge: 36px;
.text-huge {
    font-size: $font-huge;
}

$letter-spacing-1: 0.2rem;
.letter-spacing-1 {
    letter-spacing: $letter-spacing-1;
}

$font-family-assi: "Assistant", sans-serif;
.font-family-assi{
    font-family: $font-family-assi;
}

$bkg-color-transparent: transparent;
.bkg-color-transparent {
    background-color: $bkg-color-transparent;
}

$border-color-blue: 1px solid #041146 !important;
.border-color-blue {
    border: $border-color-blue;
}

$weight-700: 700 !important;
.weight-700{
    font-weight: $weight-700;
}

@for $i from 1 through 7 {
    .weight-#{$i*100} {
        font-weight: #{$i * 100} !important;
    }
}
