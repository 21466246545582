@import "./variables.scss";

.icon-1 {
  width: 18px;
  height: 18px;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

.header-background {
  width: 100%;
  height: 150px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cursor-pointer {
  cursor: pointer;
}

//BTNS
.btn {
  &.primary {
    background: $color-blue-5 !important;
    color: $color-white-1 !important;
  }
  &.border-primary {
    border: $border-solid !important;
    color: $color-blue-5 !important;
  }
}

//DIALOGS
.dialog-content {
  .inputs {
    color: rgba(37, 39, 51, 0.9) !important;
  }

  textarea {
    resize: none;
  }
}
.padding-0{
  padding: 0 !important;
}
